export const changeSeoValues = (configData) => {
  // FavIcon
  if (configData?.imageUrls?.favIcon) {
    document.getElementById('favicon')?.setAttribute('href', configData.imageUrls.favIcon)
  }
  // Title
  if(configData?.texts?.seoTitle){
    document.title = configData.texts.seoTitle
  }
  // Description
  document.querySelector('meta[name="description"]')?.setAttribute(
    'content',
    configData?.texts?.seoDescription
      ? configData.texts.seoDescription
      : 'Learning Management System'
  )
}

export const addFontFaces = (configData) => {
  configData?.fontFaces?.forEach(fontFace => {
    let newStyle = document.createElement('style');
    newStyle.appendChild(document.createTextNode(fontFace));
    document.head.appendChild(newStyle);
  });
}

export const addBackgrounds = (root, configData) => {
  root?.style.setProperty("--landing-bg-desktop","url('"+configData?.imageUrls?.landingBgDesktop+"')")
  root?.style.setProperty("--landing-bg-tablet","url('"+configData?.imageUrls?.landingBgTablet+"')")
  root?.style.setProperty("--landing-bg-mobile","url('"+configData?.imageUrls?.landingBgMobile+"')")

  root?.style.setProperty("--login-bg-desktop","url('"+configData?.imageUrls?.loginBgDesktop+"')")
  root?.style.setProperty("--login-bg-tablet","url('"+configData?.imageUrls?.loginBgTablet+"')")
  root?.style.setProperty("--login-bg-mobile","url('"+configData?.imageUrls?.loginBgMobile+"')")
}

const colorsArray = [
  {cssColor: '--primary-background-color', configColor: 'secondaryColor', defaultColor: '#FBFCFD'},
  {cssColor: '--secondary-background-color', configColor: 'secondaryBackgroundColor', defaultColor: '#FBFCFD'},
  {cssColor: '--welcome-title-color', configColor: 'welcomeTextColor', defaultColor: '#023770'},
  {cssColor: '--welcome-subtitle-color', configColor: 'secondaryTextColor', defaultColor: '#6787A9'},
  {cssColor: '--login-form-bg-color', configColor: 'cardBgColor', defaultColor: '#FFFFFF'},
  {cssColor: '--login-form-input-bg-color', configColor: 'cardBgColor', defaultColor: '#FBFCFD'},
  {cssColor: '--login-form-input-text-color', configColor: 'inputTextColor', defaultColor: '#6787A9'},
  {cssColor: '--login-form-input-border-color', configColor: 'landingTertiaryColor', defaultColor: '#E5EBF0'},
  {cssColor: '--login-form-btn-bg-color', configColor: 'primaryColor', defaultColor: '#023770'},
  {cssColor: '--login-form-btn-text-color', configColor: 'secondaryTextColor', defaultColor: '#FBFCFD'},
  {cssColor: '--login-form-links-color', configColor: 'primaryColor', defaultColor: '#6787A9'},
  {cssColor: '--input-invalid-border-color', configColor: 'errorColor', defaultColor: '#C22400'},
  {cssColor: '--form-error-color', configColor: 'errorColor', defaultColor: '#C22400'},
  {cssColor: '--form-box-shadow-color', configColor: '', defaultColor: '#0237701D'},
  {cssColor: '--header-bg-color', configColor: 'headerSecondaryColor', defaultColor: '#FBFCFD'},
  {cssColor: '--header-menu-item-color', configColor: 'secondaryTextColor', defaultColor: '#6787A9'},
  {cssColor: '--header-selected-menu-item-color', configColor: 'headerPrimaryColor', defaultColor: '#023770'},
  {cssColor: '--selected-menu-item-border-color', configColor: 'headerTertiaryColor', defaultColor: '#F39200'},
  {cssColor: '--header-dropdown-bg-color', configColor: 'headerDropdownBgColor', defaultColor: '#6787A9'},
  {cssColor: '--header-dropdown-selected-bg-color', configColor: 'primaryColor', defaultColor: '#E5EBF0'},
  {cssColor: '--header-dropdown-item-text-color', configColor: 'primaryColor', defaultColor: '#FBFCFD'},
  {cssColor: '--header-dropdown-item-subtext-color', configColor: 'headerDropdownTextColor', defaultColor: '#C0C0C0'},
  {cssColor: '--header-dropdown-text-color', configColor: 'headerDropdownTextColor', defaultColor: '#C0C0C0'},
  {cssColor: '--header-dropdown-selected-text-color', configColor: 'headerDropdownSelectedTextColor', defaultColor: '#C0C0C0'},
  {cssColor: '--header-search-input-text-color', configColor: 'primaryColor', defaultColor: '#FFFFFF'},
  {cssColor: '--footer-background-color', configColor: 'footerSecondaryColor', defaultColor: '#E5EBF0'},
  {cssColor: '--footer-color', configColor: 'footerPrimaryColor', defaultColor: '#6787A9'},
  {cssColor: '--landing-background-color', configColor: 'landingSecondaryColor', defaultColor: '#E5EBF0'},
  {cssColor: '--landing-color', configColor: 'landingPrimaryColor', defaultColor: '#6787A9'},
  {cssColor: '--landing-tertiary-color', configColor: 'landingTertiaryColor', defaultColor: '#023770'},
  {cssColor: '--scrollTop-background-color', configColor: 'primaryColor', defaultColor: '#6787A9'},
  {cssColor: '--scrollTop-color', configColor: 'secondaryTextColor', defaultColor: '#FFFFFF'},
  {cssColor: '--course-content-bg-color', configColor: '', defaultColor: '#FBFCFD'},
  {cssColor: '--all-courses-navbar-bg-color', configColor: 'navbarSecondaryColor', defaultColor: '#023770'},
  {cssColor: '--all-courses-navbar-title-color', configColor: 'navbarPrimaryColor', defaultColor: '#FBFCFD'},
  {cssColor: '--all-courses-navbar-play-icon-color', configColor: 'navbarPrimaryColor', defaultColor: '#FBFCFD'},
  {cssColor: '--all-courses-grid-item-background-color', configColor: 'cardBgColor', defaultColor: '#FFFFFF'},
  {cssColor: '--all-courses-item-time-length-color', configColor: 'cardSecondaryTextColor', defaultColor: '#6787A9'},
  {cssColor: '--all-courses-item-subtitle-color', configColor: 'tertiaryTextColor', defaultColor: '#6787A9'},
  {cssColor: '--all-courses-item-title-color', configColor: 'textColor', defaultColor: '#023770'},
  {cssColor: '--all-courses-item-no-course-color', configColor: 'noCourseColor', defaultColor: '#6787A9'},
  {cssColor: '--all-courses-item-button-background-color', configColor: 'cardBgColor', defaultColor: '#E5EBF0'},
  {cssColor: '--all-courses-item-button-primary-background-color', configColor: 'buttonPrimaryBgColor', defaultColor: '#023770'},
  {cssColor: '--all-courses-item-button-color', configColor: 'tertiaryTextColor', defaultColor: '#6787A9'},
  {cssColor: '--all-courses-item-button-primary-color', configColor: 'buttonPrimaryTextColor', defaultColor: '#FBFCFD'},
  {cssColor: '--myCourses-navbar-bg-color', configColor: 'navbarSecondaryColor', defaultColor: '#023770'},
  {cssColor: '--myCourses-navbar-text-color', configColor: 'navbarPrimaryColor', defaultColor: '#FBFCFD'},
  {cssColor: '--myCourses-navbar-selected-text-color', configColor: 'tertiaryColor', defaultColor: '#FBFCFD'},  
  {cssColor: '--selected-navbar-item-border-color', configColor: 'navbarSecondaryColor', defaultColor: '#F4B249'},
  {cssColor: '--progress-empty-color', configColor: '', defaultColor: '#E6E7F2'},
  {cssColor: '--progress-fill-color', configColor: 'primaryColor', defaultColor: '#6787A9'},
  {cssColor: '--myCourse-subjects-background-color', configColor: 'courseSubjectsPrimaryBgColor', defaultColor: '#6787A9'},
  {cssColor: '--myCourse-subjects-items-background-color', configColor: 'courseSubjectsSecondaryBgColor', defaultColor: '#526C87'},
  {cssColor: '--myCourse-subjects-items-active-bg-color', configColor: 'courseSubjectsTertiaryBgColor', defaultColor: '#34475C'},
  {cssColor: '--myCourse-subjects-color', configColor: 'courseSubjectsTextColor', defaultColor: '#FBFCFD'},
  {cssColor: '--myCourse-subjects-items-color', configColor: 'courseSubjectsTextColor', defaultColor: '#FBFCFD'},
  {cssColor: '--myCourse-subjects-lang-toggle-background-color', configColor: 'courseSubjectsSecondaryBgColor', defaultColor: '#F39200'},
  {cssColor: '--myCourse-subjects-lang-toggle-text-color', configColor: 'courseSubjectsTextColor', defaultColor: '#FBFCFD'},
  {cssColor: '--myCourse-subjects-finished-tick-color', configColor: 'courseSubjectsTextColor', defaultColor: '#FBFCFD'},
  {cssColor: '--myCourse-subjects-scrollbar-color', configColor: 'tertiaryColor', defaultColor: '#F39200'},
  {cssColor: '--myCourse-quizResult-bg-color', configColor: '', defaultColor: '#E5EBF0'},
  {cssColor: '--myCourse-quizResult-title-color', configColor: '', defaultColor: '#023770'},
  {cssColor: '--myCourse-quizResult-text-primary-color', configColor: '', defaultColor: '#3E436F'},
  {cssColor: '--myCourse-quizResult-success-color', configColor: '', defaultColor: '#03BB86'},
  {cssColor: '--myCourse-quizResult-failure-color', configColor: '', defaultColor: '#DE3A5A'},
  {cssColor: '--myCourse-quizResult-text-secondary-color', configColor: '', defaultColor: '#6787A9'},
  {cssColor: '--myCourse-quizResult-btn-bg-color', configColor: '', defaultColor: '#023770'},
  {cssColor: '--myCourse-quizResult-btn-text-color', configColor: '', defaultColor: '#FBFCFD'},
  {cssColor: '--myCourse-details-title-color', configColor: 'primaryColor', defaultColor: '#023770'},
  {cssColor: '--myCourse-details-text-color', configColor: 'tertiaryTextColor', defaultColor: '#023770'},
  {cssColor: '--myCourse-play-btn-bg-color', configColor: 'buttonSecondaryBgColor', defaultColor: '#F39200'},
  {cssColor: '--myCourse-play-btn-text-color', configColor: 'buttonSecondaryTextColor', defaultColor: '#FBFCFD'},
  {cssColor: '--myCourse-play-again-btn-text-color', configColor: 'tertiaryColor', defaultColor: '#FBFCFD'},
  {cssColor: '--myCourse-completed-text-color', configColor: 'primaryColor', defaultColor: '#FBFCFD'},
  {cssColor: '--faq-background-color', configColor: 'cardBgColor', defaultColor: '#FFFFFF'},
  {cssColor: '--faq-button-color', configColor: 'primaryColor', defaultColor: '#F39200'},
  {cssColor: '--faq-question-color', configColor: 'primaryColor', defaultColor: '#023770'},
  {cssColor: '--faq-answer-color', configColor: 'textColor', defaultColor: '#6787A9'},
  {cssColor: '--certificate-button-background-color', configColor: 'cardBgColor', defaultColor: '#FFFFFF'},
  {cssColor: '--certificate-button-color', configColor: 'textColor', defaultColor: '#023770'},
  {cssColor: '--certificate-download-icon-color', configColor: 'primaryColor', defaultColor: '#F39200'},
  {cssColor: '--no-course-text-color', configColor: 'primaryColor', defaultColor: '#6787A9'},
  {cssColor: '--no-course-btn-bg-color', configColor: 'primaryColor', defaultColor: '#023770'},
  {cssColor: '--no-course-btn-text-color', configColor: 'secondaryColor', defaultColor: '#FBFCFD'},
  {cssColor: '--profile-primary-text-color', configColor: 'tertiaryTextColor', defaultColor: '#023770'},
  {cssColor: '--profile-form-bg-color', configColor: 'cardBgColor', defaultColor: '#FFFFFF'},
  {cssColor: '--profile-form-btn-bg-color', configColor: 'primaryColor', defaultColor: '#023770'},
  {cssColor: '--profile-form-btn-text-color', configColor: 'cardBgColor', defaultColor: '#FBFCFD'},
  {cssColor: '--profile-form-input-text-color', configColor: 'primaryColor', defaultColor: '#6787A9'},
  {cssColor: '--profile-form-input-bg-color', configColor: 'cardBgColor', defaultColor: '#FBFCFD'},
  {cssColor: '--profile-form-input-border-color', configColor: 'primaryColor', defaultColor: '#E5EBF0'},
  {cssColor: '--fallback-text-color', configColor: 'textColor', defaultColor: '#6787A9'},
  {cssColor: '--career-title-primary-color', configColor: 'careerPathGraphTextColor', defaultColor: '#023770'},
  {cssColor: '--career-title-secondary-color', configColor: 'careerPathLandingTitleColor', defaultColor: '#023770'},
  {cssColor: '--career-title-tertiary-color', configColor: 'primaryColor', defaultColor: '#FFFFFF'},
  {cssColor: '--career-btn-primary-bg-color', configColor: 'primaryColor', defaultColor: '#023770'},
  {cssColor: '--career-btn-primary-text-color', configColor: 'secondaryTextColor', defaultColor: '#FBFCFD'},
  {cssColor: '--career-posProgress-bg-color', configColor: 'cardBgColor', defaultColor: '#8E9AA0'},
  {cssColor: '--career-actualpos-color', configColor: '', defaultColor: '#D1AE22'},
  {cssColor: '--career-level1pos-color', configColor: '', defaultColor: '#00AED6'},
  {cssColor: '--career-level2pos-color', configColor: '', defaultColor: '#B05C83'},
  {cssColor: '--career-posDetails-text-color', configColor: 'careerPathLandingTextColor', defaultColor: '#6787A9'},
  {cssColor: '--career-selector-bg-color', configColor: 'primaryColor', defaultColor: '#FBFCFD'},
  {cssColor: '--career-selector-text-color', configColor: 'secondaryTextColor', defaultColor: '#6787A9'},
  {cssColor: '--career-graph-actual-bg-color', configColor: 'careerPathGraphActualColor', defaultColor: '#D1AE22'},
  {cssColor: '--career-graph-required-bg-color', configColor: 'careerPathGraphRequiredColor', defaultColor: '#01CBF9'},
  {cssColor: '--devplan-primary-text-color', configColor: 'textColor', defaultColor: '#6787A9'},
  {cssColor: '--devplan-table-border-color', configColor: 'primaryColor', defaultColor: '#023770'},
  {cssColor: '--devplan-btn-primary-text-color', configColor: 'secondaryTextColor', defaultColor: '#023770'},
  {cssColor: '--errorpage-icon-color', configColor:'primaryColor', defaultColor: '#6787A9'},
  {cssColor: '--errorpage-title-color', configColor:'primaryColor', defaultColor: '#023770'},
  {cssColor: '--errorpage-text-color', configColor:'primaryColor', defaultColor: '#6787A9'},
  {cssColor: '--errorpage-btn-bg-color', configColor:'primaryColor', defaultColor: '#023770'},
  {cssColor: '--errorpage-btn-text-color', configColor:'tertiaryColor', defaultColor: '#FBFCFD'},
  {cssColor: '--error-404-page-bg-color', configColor:'error404PageBgColor', defaultColor: '#023770'},
  {cssColor: '--error-500-page-bg-color', configColor:'error500PageBgColor', defaultColor: '#FBFCFD'},
]

export const applyColors = (root, configData) => {
  for (const obj of colorsArray) {
    if(configData?.colors?.[obj.configColor]){
      root?.style.setProperty(obj.cssColor, configData.colors[obj.configColor])
    } else {
      root?.style.setProperty(obj.cssColor, obj.defaultColor)
    }
  }
}
