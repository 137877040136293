import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, Redirect, useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {post} from '../../../popleads/networking/requestService'
import {SimpleModal} from '../../../popleads/modals/reset-password/SimpleModal'

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [error, setError] = useState<any>(undefined)
  const [passwordShown, setPasswordShown] = useState(false)
  const [isPasswordChanged, setIsPasswordChanged] = useState(false)
  const [isChangeSuccess, setIsChangeSuccess] = useState(false)
  const search = useLocation().search
  const intl = useIntl()
  const email = new URLSearchParams(search).get('email')
  const codeParam = new URLSearchParams(search).get('codeParam')

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'AUTH.VALIDATION.WRONG_EMAIL'}))
      .min(3, intl.formatMessage({id: 'AUTH.VALIDATION.MIN_LENGTH_3'}))
      .max(50, intl.formatMessage({id: 'AUTH.VALIDATION.MAX_LENGTH_50'}))
      .required(intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED_EMAIL'})),
    password: Yup.string()
      .min(8, intl.formatMessage({id: 'AUTH.VALIDATION.MIN_LENGTH_8'}))
      .max(50, intl.formatMessage({id: 'AUTH.VALIDATION.MAX_LENGTH_50'}))
      .required(intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED_PASSWORD'})),
    confirmPassword: Yup.string()
      .min(8, intl.formatMessage({id: 'AUTH.VALIDATION.MIN_LENGTH_8'}))
      .max(50, intl.formatMessage({id: 'AUTH.VALIDATION.MAX_LENGTH_50'}))
      .required(intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED.CONFIRM_PASSWORD'})),
  })

  const initialValues = {
    email,
    password: '',
    confirmPassword: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        if (values.password != values.confirmPassword) {
          setError('Password and confirm password are not the same!')
          setHasErrors(true)
          setLoading(false)
          setSubmitting(false)
          return
        }
        setLoading(true)
        let requestObj = {
          fn: 'changePassword',
          email,
          newPassword: values.password,
          codeParam,
        }
        post(requestObj)
          .then(() => {
            setLoading(false)
            setIsPasswordChanged(true)
          })
          .catch((error) => {
            console.log('error:', error.response.data.text)
            setError(error.response.data.text)
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  const togglePassword = () => {
    setPasswordShown(!passwordShown)
  }

  const isRTL = document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl'

  return (
    <>
      {/* {isPasswordChanged ? <Redirect to="/auth/login" /> */}
      {isPasswordChanged && (
        <SimpleModal
          title='Success'
          successButton='Ok'
          centered={true}
          bodyClass={'isPasswordChangeModalBody'}
          modalBody={intl.formatMessage({id: 'POPLEADS.YOU_CHANGED_YOUR_PASSWORD'})}
          onClose={(resp: string) => {
            if (resp === 'OK') {
              setIsPasswordChanged(false)
              setIsChangeSuccess(true)
            }
          }}
        />
      )}
      {isChangeSuccess && <Redirect to='/auth/login' />}

      <form
        className='form w-100'
        noValidate
        id='kt_login_signin_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text mb-10'>
          {/* begin::Title */}
          <h1 className='text mb-3 title-login-form'>
            {intl.formatMessage({id: 'AUTH.GENERAL.RESET_BUTTON'})}
          </h1>
          {/* end::Title */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && error && (
          <div className='mb-lg-8 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{error}</div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>
              {intl.formatMessage({id: 'AUTH.GENERAL.FORGOT_MESSAGE'})}
            </div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-6'>
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container mb-3'>
              <div className='fv-help-block'>
                <span className='error-alert' role='alert'>
                  {formik.errors.email}
                </span>
              </div>
            </div>
          )}
          <input
            id='input-login'
            placeholder={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
            disabled={true}
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
        </div>

        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container mb-3'>
            <div className='fv-help-block'>
              <span className='error-alert' role='alert'>
                {formik.errors.password}
              </span>
            </div>
          </div>
        )}
        <div
          className={clsx(
            'd-flex login-field mb-6',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        >
          <input
            id='input-login'
            type={passwordShown ? 'text' : 'password'}
            placeholder={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
            autoComplete='off'
            style={{position: 'relative'}}
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {passwordShown ? (
            <svg
              className={clsx('eye-icon', isRTL ? 'eye-rtl' : '')}
              onClick={togglePassword}
              xmlns='http://www.w3.org/2000/svg'
              width='23.708'
              height='23.708'
              viewBox='0 0 23.708 23.708'
            >
              <g
                id='Icon_feather-eye-off'
                data-name='Icon feather-eye-off'
                transform='translate(-0.086 -0.086)'
              >
                <path
                  id='Path_154'
                  data-name='Path 154'
                  d='M17.578,19.233a9.556,9.556,0,0,1-5.638,1.956C5.3,21.189,1.5,13.594,1.5,13.594A17.514,17.514,0,0,1,6.3,7.956M9.947,6.228A8.654,8.654,0,0,1,11.94,6c6.644,0,10.44,7.594,10.44,7.594a17.562,17.562,0,0,1-2.05,3.028m-6.378-1.016a2.848,2.848,0,1,1-4.024-4.025'
                  transform='translate(0 -1.654)'
                  fill='none'
                  stroke='#8e9aa0'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                />
                <path
                  id='Path_155'
                  data-name='Path 155'
                  d='M1.5,1.5,22.38,22.38'
                  fill='none'
                  stroke='#8e9aa0'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                />
              </g>
            </svg>
          ) : (
            <svg
              className={clsx('eye-icon', isRTL ? 'eye-rtl' : '')}
              onClick={togglePassword}
              xmlns='http://www.w3.org/2000/svg'
              width='22.884'
              height='17.188'
              viewBox='0 0 22.884 17.188'
            >
              <g id='Icon_feather-eye' data-name='Icon feather-eye' transform='translate(-0.5 -5)'>
                <path
                  id='Path_149'
                  data-name='Path 149'
                  d='M1.5,13.594S5.3,6,11.942,6s10.442,7.594,10.442,7.594-3.8,7.594-10.442,7.594S1.5,13.594,1.5,13.594Z'
                  fill='none'
                  stroke='#8e9aa0'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                />
                <path
                  id='Path_150'
                  data-name='Path 150'
                  d='M19.2,16.348A2.848,2.848,0,1,1,16.348,13.5,2.848,2.848,0,0,1,19.2,16.348Z'
                  transform='translate(-4.406 -2.754)'
                  fill='none'
                  stroke='#8e9aa0'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                />
              </g>
            </svg>
          )}
        </div>

        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container mb-3'>
            <div className='fv-help-block'>
              <span className='error-alert' role='alert'>
                {formik.errors.confirmPassword}
              </span>
            </div>
          </div>
        )}

        <div
          className={clsx(
            'd-flex login-field mb-6',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        >
          <input
            id='input-login'
            type={passwordShown ? 'text' : 'password'}
            placeholder={intl.formatMessage({id: 'AUTH.INPUT.CONFIRM_PASSWORD'})}
            autoComplete='off'
            style={{position: 'relative'}}
            {...formik.getFieldProps('confirmPassword')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
              },
              {
                'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
              }
            )}
          />
          {passwordShown ? (
            <svg
              className={clsx('eye-icon', isRTL ? 'eye-rtl' : '')}
              onClick={togglePassword}
              xmlns='http://www.w3.org/2000/svg'
              width='23.708'
              height='23.708'
              viewBox='0 0 23.708 23.708'
            >
              <g
                id='Icon_feather-eye-off'
                data-name='Icon feather-eye-off'
                transform='translate(-0.086 -0.086)'
              >
                <path
                  id='Path_154'
                  data-name='Path 154'
                  d='M17.578,19.233a9.556,9.556,0,0,1-5.638,1.956C5.3,21.189,1.5,13.594,1.5,13.594A17.514,17.514,0,0,1,6.3,7.956M9.947,6.228A8.654,8.654,0,0,1,11.94,6c6.644,0,10.44,7.594,10.44,7.594a17.562,17.562,0,0,1-2.05,3.028m-6.378-1.016a2.848,2.848,0,1,1-4.024-4.025'
                  transform='translate(0 -1.654)'
                  fill='none'
                  stroke='#8e9aa0'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                />
                <path
                  id='Path_155'
                  data-name='Path 155'
                  d='M1.5,1.5,22.38,22.38'
                  fill='none'
                  stroke='#8e9aa0'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                />
              </g>
            </svg>
          ) : (
            <svg
              className={clsx('eye-icon', isRTL ? 'eye-rtl' : '')}
              onClick={togglePassword}
              xmlns='http://www.w3.org/2000/svg'
              width='22.884'
              height='17.188'
              viewBox='0 0 22.884 17.188'
            >
              <g id='Icon_feather-eye' data-name='Icon feather-eye' transform='translate(-0.5 -5)'>
                <path
                  id='Path_149'
                  data-name='Path 149'
                  d='M1.5,13.594S5.3,6,11.942,6s10.442,7.594,10.442,7.594-3.8,7.594-10.442,7.594S1.5,13.594,1.5,13.594Z'
                  fill='none'
                  stroke='#8e9aa0'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                />
                <path
                  id='Path_150'
                  data-name='Path 150'
                  d='M19.2,16.348A2.848,2.848,0,1,1,16.348,13.5,2.848,2.848,0,0,1,19.2,16.348Z'
                  transform='translate(-4.406 -2.754)'
                  fill='none'
                  stroke='#8e9aa0'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                />
              </g>
            </svg>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-between pb-lg-0'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg  fw-bolder me-4'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && (
              <span style={{color: 'white'}} className='indicator-label'>
                {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: 'block', color: 'white'}}>
                {intl.formatMessage({id: 'POPLEADS.PLEASE_WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>

          <Link to='/auth/login' className='forgot-password-text'>
            {intl.formatMessage({id: 'AUTH.GENERAL.CANCEL_BUTTON'})}
          </Link>
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
