/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import { Spinner } from 'react-bootstrap-v5';
import { useIntl } from 'react-intl';

const Loading: FC = () => {
    const intl = useIntl();
    return (
        <div className='indicatorBox'><Spinner animation="border" role="status" variant="primary">
            <span className="sr-only">
                Please wait...
            </span>
        </Spinner></div>
    )
}

export { Loading }
