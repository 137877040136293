/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {ProgressBarCircle} from './progressBarCircle'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../_metronic/helpers'
import {consideredPosition} from '../partials/consideredPositions'
import {Link} from 'react-router-dom'

interface positionBoxProps extends consideredPosition {
  currentJobRoleId: number
  setShowModal: (info: {show: boolean; deleteId: number | null}) => void
}

const PositionBox: FC<positionBoxProps> = ({
  id,
  currentJobRoleId,
  jobRoleId: targetJobRoleId,
  jobRoleName,
  matchingPercent,
  setShowModal,
}) => {
  const intl = useIntl()

  return (
    <div className='allCoursesGridItem'>
      <div className='d-flex'>
        <div className='flex-grow-1 allCoursesGridItemTitle'>{jobRoleName}</div>
        <div
          className='btn btn-icon btn-sm btn-active-light-primary ms-2'
          onClick={() => setShowModal({show: true, deleteId: id})}
        >
          <KTSVG path='/media/svg/popleads/trash.svg' className='svg-icon-1 delete-icon' />
        </div>
      </div>
      <div className='courseProgress d-flex justify-content-center'>
        <ProgressBarCircle value={matchingPercent ? matchingPercent : 0} isJobMatching />
      </div>
      <Link
        to={`/my-career-path/comparison?currentId=${currentJobRoleId}&targetId=${targetJobRoleId}`}
      >
        <button className={clsx('allCoursesGridItemButton', 'primary')}>
          {intl.formatMessage({id: 'POPLEADS.VIEW_DETAILS'})}
        </button>
      </Link>
    </div>
  )
}

export {PositionBox}
