import {FC, useState, useEffect} from 'react'
import {Spinner} from 'react-bootstrap-v5'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {post} from '../networking/requestService'

interface props {
  handleScroll: () => void
  setError: (err: any) => void
}

// ATTENTION: There are 2 seperate components for both desktop & mobile.
// If you make a change on one, don't forget to apply the same change on the other.

const MatchingJobs: FC<props> = ({handleScroll, setError}) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<any>()

  const intl = useIntl()

  useEffect(() => {
    const requestObj = {
      fn: 'getProfileBestMatchingJobRoles',
    }
    post(requestObj)
      .then(({data}) => {
        const {bestMatchings} = data
        setData(bestMatchings)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setError({text: err.response.data.text, code: err.response.status})
      })
  }, [setError])

  const actualPosData = data && data[0]
  const actualPosName = actualPosData?.jobRoleName
  const actualJobRoleId = actualPosData?.jobRoleId

  const level1PosData = data && data[1]
  const level1PosName = level1PosData?.jobRoleName
  const level1MatchRate = parseInt(level1PosData?.matchPercent || '0')
  const level1JobRoleId = level1PosData?.jobRoleId

  const level2PosData = data && data[2]
  const level2PosName = level2PosData?.jobRoleName
  const level2MatchRate = parseInt(level2PosData?.matchPercent || '0')
  const level2JobRoleId = level2PosData?.jobRoleId

  if (loading) {
    return (
      <div
        className='d-flex align-items-center justify-content-center'
        style={{width: '100%', height: '100px'}}
      >
        <Spinner animation='border' role='status' />
      </div>
    )
  }

  return (
    <div className='bestMatching'>
      <div className='container'>
        <div className='py-16'>
          <div className='myCourse_overview titleSecondary'>
            <h2 className='long'>{intl.formatMessage({id: 'POPLEADS.BEST_MATCHING_TITLE'})}</h2>
          </div>
          {/* BEGIN DESKTOP STAIRS */}
          <div className='desktopPath d-none d-md-block w-100'>
            <div className='row'>
              {/* ACTUAL */}
              <div className='col-4 px-0'>
                <div className='d-flex actual align-items-end'>
                  <div className='flex-grow-1 progressStick'>
                    <div className='matchRateProgress' style={{width: '100%'}} />
                    <div className='positionDetails matchSubtitle'>
                      {intl.formatMessage({id: 'POPLEADS_YOUR_ACTUAL'})}
                    </div>
                    <div className='positionDetails jobTitle'>{actualPosName}</div>
                  </div>
                  <div className='verticalStep' />
                </div>
              </div>
              {/* LEVEL 1 */}
              <div className='col-4 px-0'>
                <div className='d-flex level1 align-items-end'>
                  <div className='flex-grow-1 progressStick'>
                    {level1PosData ? (
                      <>
                        <div className='matchRateProgress' style={{width: `${level1MatchRate}%`}} />
                        <div className='positionDetails matchRate'>{`${level1MatchRate}%`}</div>
                        <div className='positionDetails matchSubtitle'>
                          {intl.formatMessage({id: 'POPLEADS.MATCHING'})}
                        </div>
                        <div className='positionDetails jobTitle'>{level1PosName}</div>
                      </>
                    ) : (
                      <div className='positionDetails matchSubtitle'>
                        {intl.formatMessage({id: 'POPLEADS.NO_PARENT_JOB'})}
                      </div>
                    )}
                  </div>
                  <div
                    className={`verticalStep ${level1MatchRate === 100 ? 'showGradient' : ''}`}
                  />
                </div>
              </div>
              {/* LEVEL 2 */}
              <div className='col-4 px-0'>
                <div className='d-flex level2 align-items-end'>
                  <div className='flex-grow-1 progressStick'>
                    {level2PosData ? (
                      <>
                        <div className='matchRateProgress' style={{width: `${level2MatchRate}%`}} />
                        <div className='positionDetails matchRate'>{`${level2MatchRate}%`}</div>
                        <div className='positionDetails matchSubtitle'>
                          {intl.formatMessage({id: 'POPLEADS.MATCHING'})}
                        </div>
                        <div className='positionDetails jobTitle'>{level2PosName}</div>
                      </>
                    ) : (
                      <div className='positionDetails matchSubtitle'>
                        {intl.formatMessage({id: 'POPLEADS.NO_PARENT_JOB'})}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-16 d-none d-lg-flex'>
              <div className='col-lg-4 pe-0 text-center'>
                <button
                  className='jobBtn'
                  onClick={() => {
                    handleScroll()
                  }}
                >
                  {intl.formatMessage({id: 'POPLEADS.VIEW_DETAILS'})}
                </button>
              </div>
              <div className='col-lg-4 px-0 text-center'>
                {level1PosData && (
                  <Link
                    to={`/my-career-path/comparison?currentId=${actualJobRoleId}&targetId=${level1JobRoleId}`}
                  >
                    <button className='jobBtn' disabled={!actualJobRoleId || !level1JobRoleId}>
                      {intl.formatMessage({id: 'POPLEADS.VIEW_DETAILS'})}
                    </button>
                  </Link>
                )}
              </div>
              <div className='col-lg-4 ps-0 text-center'>
                {level2PosData && (
                  <Link
                    to={`/my-career-path/comparison?currentId=${actualJobRoleId}&targetId=${level2JobRoleId}`}
                  >
                    <button className='jobBtn' disabled={!actualJobRoleId || !level2JobRoleId}>
                      {intl.formatMessage({id: 'POPLEADS.VIEW_DETAILS'})}
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
          {/* END DESKTOP STAIRS */}
          {/* BEGIN MOBILE STAIRS */}
          <div className='mobilePath d-flex flex-column d-md-none'>
            {/* LEVEL 2 */}
            <div className='d-flex level2'>
              <div className='progressStick'>
                <div className='matchRateProgress' style={{height: `${level2MatchRate}%`}} />
              </div>
              <div className='positionDetails '>
                {level2PosData ? (
                  <>
                    <div>
                      <span className='matchRate'>{`${level2MatchRate}% `}</span>
                      <span className='matchSubtitle'>
                        {intl.formatMessage({id: 'POPLEADS.MATCHING'})}
                      </span>
                    </div>
                    <div className='jobTitle'>{level2PosName}</div>
                    <Link
                      to={`/my-career-path/comparison?currentId=${actualJobRoleId}&targetId=${level2JobRoleId}`}
                    >
                      <button className='jobBtn' disabled={!actualJobRoleId || !level2JobRoleId}>
                        {intl.formatMessage({id: 'POPLEADS.VIEW_DETAILS'})}
                      </button>
                    </Link>
                  </>
                ) : (
                  <div className='matchSubtitle my-auto'>
                    {intl.formatMessage({id: 'POPLEADS.NO_PARENT_JOB'})}
                  </div>
                )}
              </div>
            </div>
            <div className={`horizontalStep2 ${level1MatchRate === 100 ? 'showGradient' : ''}`} />
            {/* LEVEL 1 */}
            <div className='d-flex level1'>
              <div className='progressStick'>
                <div className='matchRateProgress' style={{height: `${level1MatchRate}%`}} />
              </div>
              <div className='positionDetails position-level-1 '>
                {level1PosData ? (
                  <>
                    <div>
                      <span className='matchRate'>{`${level1MatchRate}% `}</span>
                      <span className='matchSubtitle'>
                        {intl.formatMessage({id: 'POPLEADS.MATCHING'})}
                      </span>
                    </div>
                    <div className='jobTitle'>{level1PosName}</div>
                    <Link
                      to={`/my-career-path/comparison?currentId=${actualJobRoleId}&targetId=${level1JobRoleId}`}
                    >
                      <button className='jobBtn' disabled={!actualJobRoleId || !level1JobRoleId}>
                        {intl.formatMessage({id: 'POPLEADS.VIEW_DETAILS'})}
                      </button>
                    </Link>
                  </>
                ) : (
                  <div className='matchSubtitle my-auto'>
                    {intl.formatMessage({id: 'POPLEADS.NO_PARENT_JOB'})}
                  </div>
                )}
              </div>
            </div>
            <div className='horizontalStep1' />
            {/* ACTUAL */}
            <div className='d-flex actual'>
              <div className='progressStick'>
                <div className='matchRateProgress' style={{height: '100%'}} />
              </div>
              <div className='positionDetails position-actual'>
                <div className='matchSubtitle'>
                  {intl.formatMessage({id: 'POPLEADS_YOUR_ACTUAL'})}
                </div>
                <div className='jobTitle'>{actualPosName}</div>
                <button
                  className='jobBtn'
                  onClick={() => {
                    handleScroll()
                  }}
                >
                  {intl.formatMessage({id: 'POPLEADS.VIEW_DETAILS'})}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* END MOBILE STAIRS */}
      </div>
    </div>
  )
}

export default MatchingJobs
