import {FC, useState, useEffect} from 'react'
import {post} from '../networking/requestService'
import {Loading} from '../partials/loading'
import {useLocation} from 'react-router-dom'
import MyCompetencyLevels from '../partials/myCompetencyLevels'
import { ComparisonSummary } from '../components/comparisonSummary'
import { Error } from '../partials/error'

const PositionComparison: FC = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState<any>()
  
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const currentId = parseInt(queryParams.get('currentId') || '')
  const targetId = parseInt(queryParams.get('targetId') || '')

  const getTargetPosCompetencyLevel = (usersJobRoleId: number, targetPosJobRoleId: number) => {
    setLoading(true)
    const requestObj = {
      fn: 'getJobRoleCompetencyListWithTarget',
      jobRoleId: usersJobRoleId,
      targetJobRoleId: targetPosJobRoleId,
    }
    post(requestObj)
      .then(({data}) => {
        setData(data)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setError({ text: err.response.data.text, code: err.response.status });
      })
  }

  useEffect(() => {
    if (currentId && targetId) {
      getTargetPosCompetencyLevel(currentId, targetId)
    }
  }, [currentId, targetId])

  if (loading) {
    return <Loading />
  }

  if(error) {
    return <Error text={error.text} code={error.code} />
  }

  return (
    <div className='row pageContainer careerPath'>
      <ComparisonSummary data={data} />
      <MyCompetencyLevels data={data} />
    </div>
  )
}

export {PositionComparison}
