/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {UserModel} from '../../modules/auth/models/UserModel'

type Props = {
  navbar: String
  setNavbar: any
}

const MyCoursesNavbar: FC<Props> = ({navbar, setNavbar}) => {
  const intl = useIntl()

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  let isTNAActive = false
  try {
    const pages = JSON.parse(user.company?.companyConfig?.pages)
    isTNAActive = !!pages?.web?.tna
  } catch (error) {
    console.log(error)
  }

  return (
    <div className='navbarMyCourses'>
      <div className='container d-flex align-items-center'>
        <div
          className={`navbarMyCourses_Item ${
            navbar === 'activeCourses' ? 'selectedWithBorder' : ''
          }`}
          onClick={() => setNavbar('activeCourses')}
        >
          <span>{intl.formatMessage({id: 'POPLEADS.MENU_ACTIVE_COURSES'})}</span>
        </div>
        <div
          className={`navbarMyCourses_Item ${navbar === 'completed' ? 'selectedWithBorder' : ''}`}
          onClick={() => setNavbar('completed')}
        >
          <span>{intl.formatMessage({id: 'POPLEADS.MENU_COMPLETED'})}</span>
        </div>
        {isTNAActive && (
          <div
            className={`navbarMyCourses_Item ${navbar === 'tna' ? 'selectedWithBorder' : ''}`}
            onClick={() => setNavbar('tna')}
          >
            <span>{intl.formatMessage({id: 'POPLEADS.TNA'})}</span>
          </div>
        )}
        {/*<div className={`navbarMyCourses_Item ${navbar === 'favorites' ? 'selectedWithBorder' : ''}`} onClick={() => setNavbar('favorites')}>
                <span>{intl.formatMessage({ id: 'POPLEADS.MENU_FAVORITES' })}</span>
            </div>*/}
      </div>
    </div>
  )
}

export {MyCoursesNavbar}
