import axios from "axios";

export const BASE_URL = "/api/ws";

export const config = {
    headers: {
      'Content-Type': 'multipart/form-data; charset=UTF-8'
    },
};

export function get(URL:String){
    return axios.get(BASE_URL + "/" + URL, config);
}

export function post(params:any) {
    let formData = new FormData();
    for (const param in params) {
        if (param !== "searchFilterVals" && param !== "searchFilterOptions" && param !== "searchFilterKeys" ) {
            if (params.hasOwnProperty(param)) {
                const val = params[param];
                if (val instanceof Array) {
                    val.forEach(v => {
                        if (v !== undefined && v !== null) {
                            formData.append(param + '[]', v);
                        }
                    });
                } else {
                    if (val !== undefined && val !== null) {
                        formData.append(param, val);
                    }
                }
            }
        }

    }

    if (params.searchFilterVals) {
        if(params.searchFilterKeys) {
            for (let i = 0; i < params.searchFilterKeys.length; i++) {
                formData.append('searchFilterKeys[]', params.searchFilterKeys[i]);
            }
        }
        if(params.searchFilterOptions) {
            for (let i = 0; i < params.searchFilterOptions.length; i++) {
                formData.append('searchFilterOptions[]', params.searchFilterOptions[i]);
            }
        }
        for (let i = 0; i < params.searchFilterVals.length; i++) {
            
            if (typeof params.searchFilterVals[i] !== 'string' && params.searchFilterVals[i].length > 1) {
                for (let j = 0; j < params.searchFilterVals[i].length; j++) {
                    formData.append("searchFilterVals[" + i + "][]", params.searchFilterVals[i][j]);
                }
            } else {
                formData.append("searchFilterVals[" + i + "][]", params.searchFilterVals[i]);
            }
        }

    }
    // console.log("sending..: ",BASE_URL + "/" + params.fn)
    return axios.post(BASE_URL + "/" + params.fn, formData, config);
  }