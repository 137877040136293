export function createCookie(name, value, days) {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    let encodedValue = encodeParam(value);
    const baseURL = window.location.href;
    const domain = baseURL.includes('localhost') ? 'localhost' : '.popleads.com';
    document.cookie = name + "=" + encodedValue + expires + "; SameSite=None; Secure; path=/; Domain=" + domain;
}

function encodeParam(s) { return encodeURIComponent(s); }


export function getDynamicValue(columnsList, fieldName) {
    if(!columnsList) return null;
    for (let i = 0; i < columnsList.length; i++) {
        if (columnsList[i].fieldName === fieldName) {
            return columnsList[i].fieldValue
        }
    }
}