import {FC, useState, useRef, useEffect} from 'react'
import {post} from '../networking/requestService'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {UserModel} from '../../modules/auth/models/UserModel'
import MatchingJobs from '../partials/matchingJobs'
import MyCompetencyLevels from '../partials/myCompetencyLevels'
import ConsideredPositions from '../partials/consideredPositions'
import {Loading} from '../partials/loading'
import { Error } from '../partials/error'

const MyCareerPath: FC = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState<any>()

  const posRef = useRef<any>(null)

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const {positionId, id: profileId} = user?.profile

  const getUsersCompetencyLevel = (positionId: number, profileId: number) => {
    const requestObj = {
      fn: 'getProfileCompetencyList',
      positionId,
      profileId,
    }
    post(requestObj)
      .then(({data}) => {
        setData(data)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setError({ text: err.response.data.text, code: err.response.status });
      })
  }

  useEffect(() => {
    getUsersCompetencyLevel(positionId, profileId)
  }, [positionId, profileId])

  const executeScroll = () =>
    window.scrollTo({
      top: posRef?.current?.offsetTop - 100,
      behavior: 'smooth',
    })

  if (loading) {
    return <Loading />
  }

  if(error) {
    return <Error text={error.text} code={error.code} />
  }

  return (
    <div className='row pageContainer careerPath'>
      <MatchingJobs handleScroll={executeScroll} setError={setError}/>
      <ConsideredPositions setError={setError}/>
      <MyCompetencyLevels ref={posRef} data={data} />
    </div>
  )
}

export {MyCareerPath}
