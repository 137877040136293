/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { useIntl } from 'react-intl'

type Props = {
    navbar: String,
    setNavbar: any
}

const MyCourseDetailNavbar: FC<Props> = ({ navbar, setNavbar }) => {
    const intl = useIntl()

    return (
        <div className='navbarMyCourses'>
             <div className='container d-flex align-items-center'>
            <div className={`navbarMyCourses_Item ${navbar === 'overview' ? 'selectedWithBorder' : ''}`} onClick={() => setNavbar('overview')}>
                <span>{intl.formatMessage({ id: 'POPLEADS.MENU_OVERVIEW' })}</span>
            </div>
            {/*<div className={`navbarMyCourses_Item ${navbar === 'discussion' ? 'selectedWithBorder' : ''}`} onClick={() => setNavbar('discussion')}>
                <span>{intl.formatMessage({ id: 'POPLEADS.MENU_DISCUSSION' })}</span>
            </div>
            <div className={`navbarMyCourses_Item ${navbar === 'notebook' ? 'selectedWithBorder' : ''}`} onClick={() => setNavbar('notebook')}>
                <span>{intl.formatMessage({ id: 'POPLEADS.MENU_NOTEBOOK' })}</span>
            </div>*/
            }
            <div className={`navbarMyCourses_Item ${navbar === 'certificates' ? 'selectedWithBorder' : ''}`} onClick={() => setNavbar('certificates')}>
                <span>{intl.formatMessage({ id: 'POPLEADS.MENU_CERTIFICATES' })}</span>
            </div>
            </div>
        </div>
    )
}

export { MyCourseDetailNavbar }
