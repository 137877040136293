import {MenuItem} from './MenuItem'
import {useIntl} from 'react-intl'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {useContext} from 'react'
import {ConfigDataContext} from '../../../../app/modules/context/configDataContext'

export function MenuInner() {
  const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  let isCareerPathActive = false
  try {
    const pages = JSON.parse(user.company?.companyConfig?.pages)
    isCareerPathActive = !!pages?.web?.careerPath
  } catch (error) {
    console.log(error)
  }

  const {configData} = useContext(ConfigDataContext)
  const company = configData?.company
  const helpEmail = configData?.texts?.helpEmail || ''

  return (
    <>
      {user.isRegistrationComplete === 1 && (
        <>
          <MenuItem
            title={intl.formatMessage({id: 'POPLEADS.ALL_COURSES'})}
            to='/'
            classNameMenuItem='headerMenuItem'
            selectedWithBorder={
              window.location.pathname.includes('/courses') || window.location.pathname === '/'
            }
          />
          <MenuItem
            title={intl.formatMessage({id: 'POPLEADS.MY_COURSES'})}
            to='/my-courses'
            classNameMenuItem='headerMenuItem'
            selectedWithBorder={window.location.pathname.includes('/my-courses')}
          />
          {isCareerPathActive && (
            <>
              <MenuItem
                title={intl.formatMessage({id: 'POPLEADS.MY_CAREER_PATH'})}
                to='/my-career-path'
                classNameMenuItem='headerMenuItem'
                selectedWithBorder={window.location.pathname.includes('/my-career-path')}
              />
              <MenuItem
                title={intl.formatMessage({id: 'POPLEADS.DEVELOPMENT_PLAN'})}
                to='/development-plan'
                classNameMenuItem='headerMenuItem'
                selectedWithBorder={window.location.pathname.includes('/development-plan')}
              />
            </>
          )}
          {company === "Olayan" && helpEmail && (
            <div className='menu-item me-lg-1 headerMenuItem'>
              <a className='menu-link py-3' href={'mailto:' + helpEmail}>
                <span className='menu-title'>{intl.formatMessage({id: 'POPLEADS.HELP'})}</span>
              </a>
            </div>
          )}
        </>
      )}
      {/*
    
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
      <MenuItem title='Layout Builder' to='/builder' />
      <MenuInnerWithSub
        title='Crafted'
        to='/crafted'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        {/* PAGES 
        <MenuInnerWithSub
          title='Pages'
          to='/crafted/pages'
          icon='/media/icons/duotune/general/gen022.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuInnerWithSub
            title='Profile'
            to='/crafted/pages/profile'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
            <MenuItem
              to='/crafted/pages/profile/connections'
              title='Connections'
              hasBullet={true}
            />
          </MenuInnerWithSub>
          <MenuInnerWithSub
            title='Wizards'
            to='/crafted/pages/wizards'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/pages/wizards/horizontal' title='Horizontal' hasBullet={true} />
            <MenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
          </MenuInnerWithSub>
        </MenuInnerWithSub>

        {/* ACCOUNT 
        <MenuInnerWithSub
          title='Accounts'
          to='/crafted/accounts'
          icon='/media/icons/duotune/communication/com006.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
          <MenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
        </MenuInnerWithSub>

        {/* ERRORS 
        <MenuInnerWithSub
          title='Errors'
          to='/error'
          icon='/media/icons/duotune/technology/teh004.svg'
          hasArrow={true}
          menuPlacement='bottom-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/error/404' title='Error 404' hasBullet={true} />
          <MenuItem to='/error/500' title='Error 500' hasBullet={true} />
        </MenuInnerWithSub>

        {/* Widgets 
        <MenuInnerWithSub
          title='Widgets'
          to='/crafted/widgets'
          icon='/media/icons/duotune/general/gen025.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
          <MenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
          <MenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
          <MenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
          <MenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
          <MenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
        </MenuInnerWithSub>
      </MenuInnerWithSub>

      <MenuInnerWithSub title='Apps' to='/apps' menuPlacement='bottom-start' menuTrigger='click'>
        {/* PAGES 
        <MenuInnerWithSub
          title='Chat'
          to='/apps/chat'
          icon='/media/icons/duotune/communication/com012.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
          <MenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
          <MenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
        </MenuInnerWithSub>
      </MenuInnerWithSub>
      <MenuInnerWithSub
        isMega={true}
        title='Mega menu'
        to='/mega-menu'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MegaMenu />
      </MenuInnerWithSub>
        */}
    </>
  )
}
