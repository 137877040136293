import {FC, useState, useEffect} from 'react'
import {Table} from 'react-bootstrap-v5'
import {post} from '../networking/requestService'
import {Error} from '../partials/error'
import {Loading} from '../partials/loading'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import moment from 'moment'
import {UserModel} from '../../modules/auth/models/UserModel'
import {DevelopmentPlanNoGoal} from '../partials/developmentPlanNoGoal'

interface DevelopmentGoalEntity {
  id: number
  developmentGoal: string
  startDate: number
  endDate: number
  purpose: string
  status: string
  competencies: any
}

const displayCompetencies = (arr: any, type: string) => {
  const comp = arr.map((comp: any) => comp?.competencyName)
  const unique = comp.filter(
    (item: any, index: number, array: any) => array.indexOf(item) === index
  )
  if (type === 'short') {
    return unique.length === 0
      ? ''
      : unique.length === 1
      ? unique[0]
      : `${unique[0]} + ${unique.length - 1} more`
  }
  if (type === 'long') {
    return unique.join(', ')
  }
}

// ATTENTION: There are 2 seperate tables for both desktop & mobile.
// If you make a change on one, don't forget to apply the same change on the other.

const DevelopmentPlan: FC = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<any>(null)
  const [data, setData] = useState<DevelopmentGoalEntity[]>()
  const [clickedCompId, setClickedCompId] = useState<number>()

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const {id: profileId} = user?.profile

  useEffect(() => {
    const requestObj = {
      fn: 'getAllIdpByProfileId',
      profileId,
    }
    post(requestObj)
      .then(({data}) => {
        const {idps} = data
        setData(idps)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setError({text: err.response.data.text, code: err.response.status})
      })
  }, [profileId])

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Error text={error.text} code={error.code} />
  }

  return (
    <>
      {data && data.length > 0 ? (
        <>
          {/* DESKTOP TABLE */}
          <div className='developmentPlan d-none d-sm-block mt-12 mx-1'>
            <Table bordered responsive='xl' className="desktopTable">
              <thead>
                <tr>
                  <th style={{minWidth: '250px'}}>Development Goal</th>
                  <th style={{minWidth: '150px'}}>Purpose</th>
                  <th style={{minWidth: '175px'}}>Start Date</th>
                  <th style={{minWidth: '175px'}}>End Date</th>
                  <th style={{minWidth: '350px'}}>Competencies</th>
                  <th style={{minWidth: '150px'}}>Status</th>
                </tr>
              </thead>
              <tbody>
                {data.map((goal: DevelopmentGoalEntity) => (
                  <tr key={goal?.id}>
                    <td>{goal?.developmentGoal}</td>
                    <td>{goal?.purpose}</td>
                    <td>{moment(goal?.startDate).format('LL')}</td>
                    <td>{moment(goal?.endDate).format('LL')}</td>
                    <td
                      className='competencies'
                      onClick={() =>
                        setClickedCompId((prevState) =>
                          prevState !== goal?.id ? goal?.id : undefined
                        )
                      }
                    >
                      {displayCompetencies(
                        goal?.competencies,
                        clickedCompId === goal?.id ? 'long' : 'short'
                      )}
                    </td>
                    <td>
                      <button className='status'>{goal?.status}</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {/* MOBILE TABLE */}
          <div className='developmentPlan d-sm-none mt-8 mx-1'>
            {data.map((goal: DevelopmentGoalEntity) => (
              <div className='mobileTable mb-8' key={goal?.id}>
                <span className='fw-bolder'>{goal?.developmentGoal}</span>
                <div className='mobileRow'>
                  <div className='title'>Purpose</div>
                  <div className='text'>{goal?.purpose}</div>
                </div>
                <div className='mobileRow'>
                  <div className='title'>Start Date</div>
                  <div className='text'>{moment(goal?.startDate).format('LL')}</div>
                </div>
                <div className='mobileRow'>
                  <div className='title'>End Date</div>
                  <div className='text'>{moment(goal?.endDate).format('LL')}</div>
                </div>
                <div className='mobileRow'>
                  <div className='title'>Competencies</div>
                  <div
                    className='text competencies'
                    onClick={() =>
                      setClickedCompId((prevState) =>
                        prevState !== goal?.id ? goal?.id : undefined
                      )
                    }
                  >
                    {displayCompetencies(
                      goal?.competencies,
                      clickedCompId === goal?.id ? 'long' : 'short'
                    )}
                  </div>
                </div>
                <div className='mobileRow'>
                  <div className='title'>Status</div>
                  <div className='text'>
                    <button className='status'>{goal?.status}</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <DevelopmentPlanNoGoal />
      )}
    </>
  )
}

export {DevelopmentPlan}
