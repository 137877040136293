/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext} from 'react'
import {Link, Redirect, Route, Switch} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {ConfigDataContext} from '../context/configDataContext'
import {useIntl} from 'react-intl'
const CustomErrorPage: React.FC = () => {
  const intl = useIntl()
  const {configData} = useContext(ConfigDataContext)

  const path = window.location.pathname

  const logoUrl = configData ? configData.imageUrls?.en?.horizontalLogo : ''
  const error404 = configData?.error404 ? configData.error404 : ''
  const error500 = configData?.error500 ? configData.error500 : ''
  return (
    <div className='errorPage' id={path == '/error/500' ? 'error500':'error404'}>
      {logoUrl ? (
        <img alt='Logo' src={logoUrl} className='logo-default mb-5' width='180' />
      ) : (
        <span className='toolbarName'>LOGO</span>
      )}
      <Switch>
        <Route path='/error/404' exact={true}>
          {error404 ? <img
          className='errorImage'
          src={error404}
          alt='404'
          />:<KTSVG path='/media/svg/popleads/404.svg' />}
          <h1 className='text-center'>{intl.formatMessage({id: 'POPLEADS.404_TITLE'})}</h1>
          <p className='text-center'>{intl.formatMessage({id: 'POPLEADS.404_TEXT'})}</p>
          <Link className='mx-auto mx-lg-0' to='/'>
            <button>{intl.formatMessage({id: 'POPLEADS.GOTO_ALLCOURSES'})}</button>
          </Link>
        </Route>
        <Route path='/error/500' exact={true}>
          {error500 ? <img
          className='errorImage' 
          src={error500}
          alt='500'
          />:<KTSVG path='/media/svg/popleads/500.svg' />}
          <h1 className='text-center'>{intl.formatMessage({id: 'POPLEADS.500_TITLE'})}</h1>
          <p className='text-center'>{intl.formatMessage({id: 'POPLEADS.500_TEXT'})}</p>
          <Link className='mx-auto mx-lg-0' to='/'>
            <button>{intl.formatMessage({id: 'POPLEADS.GOTO_ALLCOURSES'})}</button>
          </Link>
        </Route>
        <Redirect from='/error' exact={true} to='/error/404' />
        <Redirect to='/error/404' />
      </Switch>
    </div>
  )
}

export {CustomErrorPage}
