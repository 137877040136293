/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {CourseBox} from '../components/courseBox'
import {MyCoursesNavbar} from '../components/myCoursesNavbar'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {UserModel} from '../../modules/auth/models/UserModel'
import {getDynamicValue} from '../functions/util'
import {MyCoursesNoCourse} from './myCoursesNoCourse'
import {ProgressBarCircle} from '../components/progressBarCircle'
import { courseType } from '../constants'
// import clsx from 'clsx'

interface MyCoursesProps {
  campaignCurrentList?: any
  campaignHistoryList?: any
  KPIData?: any
}

const MyCoursesWithCourses: FC<MyCoursesProps> = (props: MyCoursesProps) => {
  const intl = useIntl()
  const [navbar, setNavbar] = useState('activeCourses')
  const [courses, setCourses] = useState<any>([])
  const [KPIValues, setKPIValues] = useState<any>(null)

  useEffect(() => {
    setCourseLMSProgress(props.campaignCurrentList)
    setCourses(props.campaignCurrentList)
    setKPIValues({
      kpiTotalHours: props.KPIData?.kpiTotalHours,
      kpiCompletedHours: props.KPIData?.kpiCampaignTotalHours,
    })
  }, [])
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const setCourseLMSProgress = (campaigns: any) => {
    if (user.profile.etiket) {
      for (const course of campaigns) {
        for (const etiket of user.profile.etiket) {
          if (etiket.campaignId === course.id) {
            course['lmsProgress'] = +etiket.lmsProgress.split('%')[0]
          }
        }
      }
    }
  }
  const isCourseJoinable = (course: any) => {
    const today = Date.now()
    if (today > course.basTarih && today < course.bitTarih && courseType.LVT.includes(course.type)) {
      return true
    }
  }
  const navBarChange = (navBar: any) => {
    if (navBar === 'activeCourses') {
      setCourseLMSProgress(props.campaignCurrentList)
      setCourses(props.campaignCurrentList)
    } else if (navBar === 'completed') {
      setCourseLMSProgress(props.campaignHistoryList)
      setCourses(props.campaignHistoryList)
    } else if (navBar === 'tna') {
      setCourses(props.KPIData?.kpiList)
    }
    setNavbar(navBar)
  }
  const overallKPIProgressRate =
    (KPIValues?.kpiCompletedHours / KPIValues?.kpiTotalHours) * 100 || 0

  return (
    <>
      <div className='row allCourses'>
        <MyCoursesNavbar
          navbar={navbar}
          setNavbar={(returnNavbar: any) => navBarChange(returnNavbar)}
        />
        {/*<div className='d-flex w-100'>
                <div className='allCoursesIcons myCourses'>
                    <span>
                        <KTSVG
                            path='/media/svg/popleads/grid.svg'
                            className='svg-icon-1 grid'
                        />
                    </span>
                    <span>
                        <KTSVG
                            path='/media/svg/popleads/list.svg'
                            className='svg-icon-1'
                        />
                    </span>
                    <select>
                        <option>Default Sorting</option>
                    </select>
                </div>
                
            </div>*/}

        {courses.length === 0 && (
          <div className='allCoursesGrid noCourse'>
            <MyCoursesNoCourse />
          </div>
        )}

        {navbar !== 'tna' && courses.length > 0 && (
          <div className='allCoursesGrid'>
            {courses.map((course: any) => (
              <CourseBox
                key={course.id}
                title={course.ad}
                type={course.type}
                subtitle={getDynamicValue(course.columnsList, 'Short Description')}
                hour={getDynamicValue(course.columnsList, 'Video Hours')}
                lesson={getDynamicValue(course.columnsList, 'Number of Lectures')}
                language={getDynamicValue(course.columnsList, 'Language')}
                primary={course.basTarih !== null}
                primaryText={intl.formatMessage({id: 'POPLEADS_RESUME_COURSE'})}
                progressValue={course.lmsProgress}
                imgUrl={course.imgURL}
                id={course.id}
                basTarih={course.basTarih}
                bitTarih={course.bitTarih}
                isCompleted={navbar === 'completed'}
                isCourseJoinable={isCourseJoinable(course)}
                JoinText={intl.formatMessage({id: 'POPLEADS.JOIN_COURSE'})}
                completedText={intl.formatMessage({id: 'POPLEADS_COURSE_COMPLETED'})}
                link={course.link}
              />
            ))}
          </div>
        )}

        {navbar === 'tna' && courses.length > 0 && (
          <>
            <div className='trainingKPI'>
              <h3 className='title'>{intl.formatMessage({id: 'POPLEADS.TRAINING_KPI'})}</h3>
              <div className='d-flex flex-column flex-sm-row align-items-sm-center'>
                <div className='d-flex mt-6 mb-sm-0'>
                  <div className='d-flex flex-grow-1 align-items-center'>
                    <span className='values'>{KPIValues?.kpiTotalHours}</span>
                    <span className='labels ms-4'>Total Hours</span>
                  </div>
                  <div className='d-flex flex-grow-1 align-items-center mx-sm-16'>
                    <span className='values'>
                      {KPIValues?.kpiTotalHours - KPIValues?.kpiCompletedHours}
                    </span>
                    <span className='labels ms-4'>Remaining Hours</span>
                  </div>
                </div>
                <div className='d-flex align-items-center mt-6 mb-sm-0'>
                  <ProgressBarCircle isWithText={false} value={overallKPIProgressRate} />
                  <span className='labels ms-4 fw-bolder'>{`${overallKPIProgressRate}%`}</span>
                  <span className='labels ms-1'>Completed</span>
                </div>
              </div>
            </div>
            <div className='allCoursesGrid'>
              {courses.map((course: any) => {
                const progressValue =
                  (course.kpiCampaignTotalHours /
                    (course.kpiCampaignTotalHours + course.kpiHours)) *
                    100 || 0
                const mainCampaign = [
                  ...props.campaignCurrentList,
                  ...props.campaignHistoryList,
                ].find((campaign: any) => campaign.courseId === course.campaignId)
                return (
                  <CourseBox
                    key={course.campaignId}
                    title={course.campaignName}
                    type={course.type}
                    hour={course.kpiHours}
                    subtitle={getDynamicValue(mainCampaign?.columnsList, 'Short Description')}
                    lesson={getDynamicValue(mainCampaign?.columnsList, 'Number of Lectures')}
                    language={getDynamicValue(mainCampaign?.columnsList, 'Language')}
                    primary={!!mainCampaign?.basTarih}
                    primaryText={intl.formatMessage({id: 'POPLEADS_RESUME_COURSE'})}
                    progressValue={progressValue}
                    imgUrl={course.imgURL}
                    id={course.campaignId}
                    isCompleted={progressValue === 100}
                    completedText={intl.formatMessage({id: 'POPLEADS_COURSE_COMPLETED'})}
                    isCourseJoinable={isCourseJoinable(course)}
                    JoinText={intl.formatMessage({id: 'POPLEADS.JOIN_COURSE'})}
                    link={mainCampaign?.link}
                  />
                )
              })}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export {MyCoursesWithCourses}
