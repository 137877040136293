import {FC, useEffect, useState, useCallback, useContext, useMemo} from 'react'
import {Spinner} from 'react-bootstrap-v5'
import {useIntl} from 'react-intl'
import {shallowEqual, useSelector} from 'react-redux'
import Select from 'react-select'
import {RootState} from '../../../setup'
import {KTSVG} from '../../../_metronic/helpers'
import {UserModel} from '../../modules/auth/models/UserModel'
import {ConfigDataContext} from '../../modules/context/configDataContext'
import {PositionBox} from '../components/positionBox'
import {SimpleModal} from '../modals/reset-password/SimpleModal'
import {post} from '../networking/requestService'

interface dropdownOption {
  value: number
  label: string
}
interface groupedOption {
  label: string
  options: dropdownOption[]
}
export interface consideredPosition {
  id: number
  jobRoleId: number
  jobRoleName: string
  matchingPercent: number
}

const getDropdownStyles = (configData: any) => {
  return {
    control: (styles: any, status: any) => ({
      ...styles,
      backgroundColor: configData?.colors?.careerSelectorBgColor || 'inherit',
      border: 0,
      opacity: status.isDisabled ? 0.75 : 1,
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: configData?.colors?.careerSelectorTextColor || 'inherit',
    }),
    menu: (styles: any) => ({
      ...styles,
      color: configData?.colors?.careerSelectorTextColor || 'inherit',
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: configData?.colors?.careerSelectorTextColor || 'inherit',
    }),
    clearIndicator: (styles: any) => ({
      ...styles,
      color: configData?.colors?.careerSelectorTextColor || 'inherit',
    }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      color: configData?.colors?.careerSelectorTextColor || 'inherit',
    }),
    indicatorSeparator: (styles: any) => ({
      ...styles,
      display: 'none',
    }),
    // option: (styles: any, status: any) => ({
    //   ...styles,
    //   backgroundColor: status.isSelected ? "red" : "inherit"
    // })
  }
}

const ConsideredPositions: FC<{setError: (err: any) => void}> = ({setError}) => {
  const intl = useIntl()

  const [unitOptions, setUnitOptions] = useState<groupedOption[]>()
  const [jobRoleOptions, setJobRoleOptions] = useState<dropdownOption[]>()
  const [selectedUnit, setSelectedUnit] = useState<dropdownOption | null>(null)
  const [selectedJobRole, setSelectedJobRole] = useState<dropdownOption | null>(null)
  const [consideredPositions, setConsideredPositions] = useState<consideredPosition[]>()

  const [loading, setLoading] = useState(true)
  const [showDropdowns, setShowDropdowns] = useState(false)
  const [showModal, setShowModal] = useState<{show: boolean; deleteId: number | null}>({
    show: false,
    deleteId: null,
  })

  const {configData} = useContext(ConfigDataContext)
  const dropdownStyles = useMemo(() => getDropdownStyles(configData), [configData])

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const {unitId, unitName, jobRoleId: currentJobRoleId} = user?.profile

  const numOfSelectedJobRoles = consideredPositions ? consideredPositions.length : 0
  const isMaxRoleLimitExceeded = numOfSelectedJobRoles >= 4

  const getInterestedJobRoles = useCallback(() => {
    if (unitId) {
      const requestObj = {
        fn: 'getAllJobRoleInterest',
      }
      post(requestObj)
        .then(({data}) => {
          setConsideredPositions(data?.interests)
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          setError({text: err.response.data.text, code: err.response.status})
        })
    }
  }, [unitId, setError])

  const addJobRole = (option: dropdownOption) => {
    setLoading(true)
    const requestObj = {
      fn: 'insertJobRoleInterests',
      jobRoleIds: [option.value],
    }
    post(requestObj)
      .then(() => {
        getInterestedJobRoles()
      })
      .catch((err) => {
        setLoading(false)
        setError({text: err.response.data.text, code: err.response.status})
      })
  }

  const removeJobRole = (id: number) => {
    setLoading(true)
    const requestObj = {
      fn: 'removeJobRoleInterest',
      interestId: id,
    }
    post(requestObj)
      .then(() => {
        getInterestedJobRoles()
      })
      .catch((err) => {
        setLoading(false)
        setError({text: err.response.data.text, code: err.response.status})
      })
  }

  useEffect(() => {
    const requestObj = {
      fn: 'getAllUnits',
    }
    post(requestObj)
      .then(({data}) => {
        let groupedOptions: groupedOption[] = []
        for (const department of data?.departments) {
          groupedOptions.push({
            label: department?.name,
            options: department?.unitList.map((unit: any) => ({
              value: unit?.id,
              label: unit?.name,
            })),
          })
        }
        setUnitOptions(groupedOptions)

        if (unitId && unitName) {
          setSelectedUnit({value: unitId, label: unitName})
        }
        getInterestedJobRoles()
      })
      .catch((err) => {
        setError({text: err.response.data.text, code: err.response.status})
      })
  }, [unitId, unitName, getInterestedJobRoles, setError])

  useEffect(() => {
    setSelectedJobRole(null)
    if (selectedUnit) {
      const requestObj = {
        fn: 'getJobRoles',
        unitId: selectedUnit?.value,
      }
      post(requestObj)
        .then(({data}) => {
          let arr: dropdownOption[] = []
          for (const role of data?.jobRoleList) {
            arr.push({value: role.id, label: role.name})
          }
          setJobRoleOptions(arr)
        })
        .catch((err) => {
          setError({text: err.response.data.text, code: err.response.status})
        })
    }
  }, [selectedUnit, setError])

  useEffect(() => {
    if (isMaxRoleLimitExceeded) {
      setShowDropdowns(false)
    }
  }, [isMaxRoleLimitExceeded])

  if (loading) {
    return (
      <div
        className='d-flex align-items-center justify-content-center'
        style={{width: '100%', height: '100px'}}
      >
        <Spinner animation='border' role='status' />
      </div>
    )
  }

  return (
    <>
      {showModal.show && (
        <SimpleModal
          centered={true}
          title={intl.formatMessage({id: 'POPLEADS.CONFIRMATION'})}
          modalBody={intl.formatMessage({id: 'POPLEADS.ARE_YOU_SURE_DELETE'})}
          cancelButton={intl.formatMessage({id: 'AUTH.GENERAL.CANCEL_BUTTON'})}
          deleteButton={intl.formatMessage({id: 'POPLEADS.DELETE'})}
          onClose={(resp: string) => {
            setShowModal({show: false, deleteId: null})
            if (resp === 'Delete' && showModal.deleteId) {
              removeJobRole(showModal.deleteId)
            }
          }}
        />
      )}
      <div className='mt-12'>
        <div className='myCourse_overview titlePrimary'>
          <h2 className='long'>{intl.formatMessage({id: 'POPLEADS.POSITIONS_CONSIDERING'})}</h2>
        </div>
        <div className='d-flex align-items-center mt-6'>
          <button
            className='jobBtn'
            disabled={isMaxRoleLimitExceeded}
            onClick={() => setShowDropdowns(true)}
            style={{opacity: isMaxRoleLimitExceeded ? '0.6' : '1'}}
          >
            {intl.formatMessage({id: 'POPLEADS.ADD_JOB'})}
          </button>
          <span className='selectedRole mx-6'>{`Selected ${numOfSelectedJobRoles} job role`}</span>
        </div>
        {showDropdowns && (
          <div className='allCoursesGrid mt-6 mx-0'>
            <Select
              className='jobSelect'
              value={selectedUnit}
              onChange={(option) => setSelectedUnit(option)}
              options={unitOptions}
              isClearable
              placeholder='Select Unit'
              styles={dropdownStyles}
            />
            <Select
              className='jobSelect'
              value={selectedJobRole}
              onChange={(option) => setSelectedJobRole(option)}
              options={jobRoleOptions}
              isClearable
              isDisabled={!selectedUnit || !jobRoleOptions}
              placeholder='Select Job Role'
              styles={dropdownStyles}
            />
            <button
              disabled={!selectedJobRole?.value}
              onClick={() => {
                if (selectedJobRole?.value) {
                  addJobRole(selectedJobRole)
                }
              }}
              className='addJob'
            >
              <KTSVG path='/media/svg/popleads/faqPlus.svg' className='svg-icon' />
            </button>
          </div>
        )}
        {consideredPositions && consideredPositions.length > 0 && (
          <div className='allCoursesGrid mt-6 mx-0'>
            {consideredPositions.map((position) => (
              <PositionBox
                key={position.id}
                id={position.id}
                currentJobRoleId={currentJobRoleId}
                jobRoleId={position.jobRoleId}
                jobRoleName={position.jobRoleName}
                matchingPercent={position.matchingPercent}
                setShowModal={setShowModal}
              />
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export default ConsideredPositions