/* eslint-disable jsx-a11y/anchor-is-valid */
// import clsx from 'clsx'
import {FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  value: number
  isJobMatching?: boolean
  isWithText?: boolean
}

const ProgressBarCircle: FC<Props> = ({value, isJobMatching, isWithText = true}) => {
  const intl = useIntl()
  const leftValue = value > 50 ? ((value - 50) * 180) / 50 : 0
  const left = 'rotate(' + leftValue + 'deg)'
  const rightValue = value < 50 ? (value * 180) / 50 : 180
  const right = 'rotate(' + rightValue + 'deg)'
  return (
    <div className='d-flex flex-column align-items-center'>
      <div className='progressC'>
        <span className='progress-left'>
          <span className='progressBar' style={{transform: left}}></span>
        </span>
        <span className='progress-right'>
          <span className='progressBar' style={{transform: right}}></span>
        </span>
        {isWithText && <div className='progress-value'>{value}%</div>}
      </div>
      {isWithText && (
        <div className='progress-completed'>
          {intl.formatMessage({
            id: isJobMatching ? 'POPLEADS.MATCHING' : 'POPLEADS_COURSE_COMPLETED',
          })}
        </div>
      )}
    </div>
  )
}

export {ProgressBarCircle}
