import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, Switch } from 'react-router-dom'
import * as auth from './redux/AuthRedux'
import { post } from '../../popleads/networking/requestService'

export function Logout() {
  const dispatch = useDispatch()
  useEffect(() => {
    post({ "fn": "cikis" }).then(() => {
      dispatch(auth.actions.logout())
      document.location.reload()
    })
  }, [dispatch])

  return (
    <Switch>
      <Redirect to='/auth/login' />
    </Switch>
  )
}
