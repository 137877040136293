import {FC} from 'react'
import { useIntl } from 'react-intl'

interface props {
  data: any
}

const ComparisonSummary: FC<props> = ({data}) => {
  
  const intl = useIntl()

  return (
    <div className='comparison'>
      <div className='container'>
        <div
          className='row justify-content-center w-100 mx-auto py-10'
          style={{maxWidth: '1200px'}}
        >
          <div className='d-flex justify-content-between'>
            <span className='comparisonSubtitle'>{intl.formatMessage({id: 'POPLEADS_YOUR_ACTUAL'})}</span>
            <span className='d-none d-md-block comparisonSubtitle'>{intl.formatMessage({id: 'POPLEADS.MATCHING'})}</span>
          </div>
          <div className='d-flex flex-column flex-md-row justify-content-between'>
            <div className='myCourse_overview'>
              <h2 className='long comparisonTitle'>{data?.positionName}</h2>
            </div>
            <span className='comparisonTitle'>vs.</span>
            <div className='d-md-none mb-1'>
              <span className='mobilePercentage'>{`${parseFloat(data?.percentage || "0")}% `}</span>
              <span className='comparisonSubtitle'>{intl.formatMessage({id: 'POPLEADS.MATCHING'})}</span>
            </div>
            <div className='myCourse_overview'>
              <h2 className='long comparisonTitle'>{data?.targetName}</h2>
            </div>
            <span className='d-none d-md-block comparisonTitle'>{`${parseFloat(data?.percentage || "0")}%`}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ComparisonSummary}
