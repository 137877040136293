import {FC, useEffect, useState} from 'react'
import {post} from '../networking/requestService'
import {MyCoursesNoCourse} from '../partials/myCoursesNoCourse'
import {MyCoursesWithCourses} from '../partials/myCoursesWithCourses'
import {Error} from '../partials/error'
import {Loading} from '../partials/loading'

const MyCourses: FC = () => {
  const [courses, setCourses] = useState<any>(null)
  const [KPIData, setKPIData] = useState<any>(null)
  const [error, setError] = useState<any>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const requestObj = {
      fn: 'getProfileCampaigns',
    }
    post(requestObj)
      .then(
        ({
          data: {
            campaignCurrentList,
            campaignHistoryList,
            kpiList,
            kpiTotalHours,
            kpiCampaignTotalHours,
          },
        }) => {
          setCourses({campaignCurrentList, campaignHistoryList})
          setKPIData({kpiList, kpiTotalHours, kpiCampaignTotalHours})
          setLoading(false)
        }
      )
      .catch((err) => {
        setError({text: err.response.data.text, code: err.response.status})
        setLoading(false)
      })
  }, [])

  return (
    <>
      {' '}
      {loading ? (
        <Loading />
      ) : error ? (
        <Error text={error.text} code={error.code} />
      ) : courses.campaignCurrentList.length === 0 &&
        courses.campaignHistoryList.length === 0 &&
        KPIData?.kpiList?.length === 0 ? (
        <MyCoursesNoCourse />
      ) : (
        <MyCoursesWithCourses
          campaignCurrentList={courses.campaignCurrentList}
          campaignHistoryList={courses.campaignHistoryList}
          KPIData={KPIData}
        />
      )}
    </>
  )
}

export {MyCourses}
