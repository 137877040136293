/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { useIntl } from 'react-intl';
// import { useIntl } from 'react-intl'
import { KTSVG } from '../../../_metronic/helpers';
import { post } from '../networking/requestService';

type Props = {
    course: any,
}

const Certificates: FC<Props> = (props: Props) => {
    const intl = useIntl();
    const { course } = props;

    // const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    const getCertificate = () => {
        setLoading(true);
        const lmsData = JSON.parse(course.campaign.lmsUnits);
        const queryParams = {
            fn: 'getCertificateDownloadToken',
            campaignId: course.campaign.id,
            issueDate: lmsData.certificateDate
        };
        post(queryParams)
            .then(({ data: { token } }) => {
                const link = window.location.protocol + '//' + window.location.host + '/api/1/download?token=' + token;
                setLoading(false);
                window.open(link, '_blank');
            })
            .catch((err) => {
                // setError({ text: err.response.data.text, code: err.response.status });
                setLoading(false);
            });
    }

    return (
        <div className='certificatesTab row'>
            <div className='col-md-4 certificate'>
                <button disabled={loading} onClick={() => getCertificate()} className='certificateButton'>
                    <div className='d-flex justify-content-between'>
                        <div>{intl.formatMessage({ id: 'POPLEADS.CERTIFICATE' })}</div>
                        <div>
                            {loading && <div className="spinner-border" />}
                            {!loading &&
                                <KTSVG
                                    path='/media/svg/popleads/download.svg'
                                    className=''
                                />}

                        </div>
                    </div>
                </button>
            </div>
        </div>
    )
}

export { Certificates }
