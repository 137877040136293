import React, {useState} from 'react'

export const ConfigDataContext = React.createContext<any>({
  configData: {},
  setConfigData: () => {},
  keycloakConfig: {},
  setKeycloakConfig: () => {},
})

const ConfigDataProvider: React.FC<React.ReactNode> = ({children}) => {
  const [configData, _setConfigData] = useState()
  const [keycloakConfig, _setKeycloakConfig] = useState()

  const setConfigData = (data: any) => _setConfigData(data)
  const setKeycloakConfig = (data: any) => _setKeycloakConfig(data)
  return (
    <ConfigDataContext.Provider
      value={{configData, setConfigData, keycloakConfig, setKeycloakConfig}}
    >
      {children}
    </ConfigDataContext.Provider>
  )
}

export default ConfigDataProvider
